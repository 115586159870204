(function () {
  'use strict';

  class Modules {
    constructor(ModulesRest, $cookies, $rootScope) {
      this.ModulesRest = ModulesRest;
      this.$cookies = $cookies;
      this.$rootScope = $rootScope;
    }

    modulesIndex(modules = {}, callback = angular.noop) {
      return this.ModulesRest.modules().get(modules,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    modulesCreate(modules = {}, callback = angular.noop) {
      return this.ModulesRest.modules().save({module_document: modules},
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    goTo(modules = {}, callback = angular.noop) {
      return this.ModulesRest.goTo().save(modules,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    myModules(module = {}, callback = angular.noop) {
      return this.ModulesRest.myModules().get(module,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    moduleView(module = {}, callback = angular.noop) {
      return this.ModulesRest.moduleId().get({id: module.id},
        function (data) {
          const {module_document: {resource_nodes: {length}}} = data;
          data.module_document.has_nodes = length > 0 ? true : false;
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    moduleTree(module = {}, callback = angular.noop) {
      return this.ModulesRest.moduleTree().get(module,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    moduleEdit(modules = {}, callback = angular.noop) {
      return this.ModulesRest.module().update({module_document: modules},
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    moduleDelete(module = {}, callback = angular.noop) {
      return this.ModulesRest.module().remove(module,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    resourceNodesIndex(nodes = {}, callback = angular.noop) {
      const {module_id} = nodes;
      return this.ModulesRest.nodes().get({module_id: module_id},
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    resourceNodesCreate(module = {}, callback = angular.noop) {
      const {resource_nodes, id} = module;
      return this.ModulesRest.nodes().save({resource_nodes: resource_nodes, module_id: id},
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    singleNodeGet(module = {}, callback = angular.noop) {
      return this.ModulesRest.singleNode().get(module,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    discussionEntries(module = {}, callback = angular.noop) {
      return this.ModulesRest.discussionEntries().get(module,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    submitNode(module = {}, callback = angular.noop) {
      return this.ModulesRest.submit().save(module,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    resourceNodeDecision(nodes = {}, callback = angular.noop) {
      return this.ModulesRest.resourceNodeDecision().save(nodes,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    resourceNodeDecisionIndex(nodes = {}, callback = angular.noop) {
      return this.ModulesRest.resourceNodeDecision().get(nodes,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    resourcePollsCreate(polls = {}, callback = angular.noop) {
      return this.ModulesRest.resourcePolls().save(polls,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    resourcePollsIndex(polls = {}, callback = angular.noop) {
      return this.ModulesRest.resourcePolls().get(polls,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    resourceMyResults(polls = {}, callback = angular.noop) {
      polls.my_results = 'my-results';
      return this.ModulesRest.resourcePolls().get(polls,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    resourceNodeIndex(node = {}, callback = angular.noop) {
      return this.ModulesRest.resourceNode().get(node,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    resourceNodes(node = {}, callback = angular.noop) {
      return this.ModulesRest.resourceNodes().get(node,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    resourceNodeUpdate(node = {}, callback = angular.noop) {
      return this.ModulesRest.resourceNode().update(node,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    resourceNodeDelete(node = {}, callback = angular.noop) {
      return this.ModulesRest.resourceNode().remove(node,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    resourceAssessmentResultsIndex(assessment = {}, callback = angular.noop) {
      assessment.assessment_results = 'assessment-results';
      return this.ModulesRest.resourceNode().get(assessment,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    resourceAssessmentResultsCreate(assessment = {}, callback = angular.noop) {
      assessment.assessment_results = 'assessment-results';
      return this.ModulesRest.resourceNode().save(assessment,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    resourceAssessmentResultsGet(assessment = {}, callback = angular.noop) {
      assessment.assessment_results = 'assessment-results';
      assessment.my_results = 'my-results';
      return this.ModulesRest.resourceNode().get(assessment,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    lastAccessedResourceGet(obj = {}, callback = angular.noop) {
      return this.ModulesRest.lastAccessedResource().get(obj,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    resourceLogCreate(log = {}, callback = angular.noop) {
      return this.ModulesRest.resourceLog().save(log,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    moduleClone(id, modules = {}, callback = angular.noop) {
      modules.id = id;
      modules.clone = 'clone';
      return this.ModulesRest.module().save({module_document: modules},
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    lomMetadata(lomMetadata = {}, callback = angular.noop) {
      return this.ModulesRest.lomMetadata().get(lomMetadata,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }
  }

  /**
   * @ngdoc service
   * @name components.service:Modules
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('Modules', Modules);
}());
